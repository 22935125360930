html, body {
  height: 100%;
}

#root, .App, #map {
  height: 100%;
  width: 100%;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.modal-content {
  border: 0 !important;
}

.modal-header {
  border-bottom: 0px !important;
}